import React from 'react';
import { TextBoxFull, Wrapper, FlexContainer } from '../styles/globalstyles';
import SEO from '../components/seo';
import {css} from '@emotion/react';


const Impressum = () => (
<Wrapper>
    <SEO title="Impressum" />
    <FlexContainer direction="column" align="flex-start" justify="space-between" css={css`padding-top: 8em !important;`}>
        <h1>Impressum </h1>

        <TextBoxFull>
            <h4>Seitenbetreiber i.S.d. § 5 TMG</h4>
            <p> Proceed GmbH & Co KG <br />
                Salzburger Weg 10 <br />
                79111 Freiburg </p>
            <p> E-Mail: info@proceed.gmbh <br />
                Büro: 0761 / 897 61 83 <br />
                Internet: www.proceed.gmbh</p>
            <p>Amtsgericht Registergericht Freiburg i.Br. - HRA 705962</p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>Persönlich haftende Gesellschafterin</h4>
            <p> Proceed Verwaltungs-GmbH - Sitz Freiburg <br />
                Amtsgericht Registergericht Freiburg i.Br. - HRB 719910  </p>
            <p> Geschäftsführende Gesellschafter: <br />
                Dr. Ulrich Lohmann, Michael Kirsch </p>
        </TextBoxFull>
        <TextBoxFull>
            <h4>Urheberrechte und Bildnachweise</h4>
            <p> Die Inhalte von www.proceed.gmbh sind - soweit nicht abweichend angegeben - urheberrechtlich geschützt.
                Verwendete Fotografien sind ggf. mit Bildnachweisen gekennzeichnet oder unten aufgeführt, soweit sie nicht selbst angefertigt wurden.
                Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz der Urheber möglich. </p>
        </TextBoxFull>
        
        
    </FlexContainer>


</Wrapper>

)
export default Impressum;